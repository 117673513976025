import React from "react";

const  Navbar=() =>{
  return (
  <div style={{minHeight:"100px",display:"flex",alignItems:"center",justifyContent:"end", backgroundColor:"red"}}>
    hello
  </div>
  );
}

export default Navbar;
